.textWrapper {
  position: relative;
  bottom: 128px;
  width: 17rem;
  height: 33rem;
  margin: 10px 15px;
  background-color: white;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.4);
  border-radius: 2px;

  @media (max-width: 1340px) {
    width: 12rem;
    height: 32rem;
  }
  @media (max-width: 1100px) {
    width: 10rem;
    height: 34rem;
  }
  @media (max-width: 930px) {
    width: 32rem;
    height: 22rem;
  }
  @media (max-width: 730px) {
    width: 32rem;
    height: 22rem;
  }
  @media (max-width: 570px) {
    width: 28rem;
    height: 27rem;
  }
  @media (max-width: 470px) {
    width: 22rem;
    height: 30rem;
  }
  @media (max-width: 400px) {
    width: 20rem;
    height: 32rem;
  }

  .numberBackground {
    width: 6rem;
    min-height: 6rem;
    background-size: auto;
    margin: 1rem 7rem 0 2rem;
    color: #ffffff;
    background-color: #282c34;
    border-radius: 50px !important;

    .numberForm {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 6rem;

      h2 {
        font-family: Oswald, sans-serif !important;
        line-height: 1.1;
        font-size: 3rem;
        font-weight: 400;
        margin: 0 5px;
      }
    }
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1rem 1.5rem 0;
    hyphens: auto;

    @media (max-width: 1340px) {
      font-size: 1.2rem;
      margin: 0.5rem 1rem 0;
    }
    @media (max-width: 1100px) {
      font-size: 1.05rem;
    }

    @media (max-width: 930px) {
      font-size: 1.5rem;
    }

    @media (max-width: 730px) {
      font-size: 1.6rem;
    }
  }

  p {
    font-style: italic;
    margin: 1rem 1.5rem 2rem;
    line-height: 1.8;
    hyphens: auto;

    @media (max-width: 1340px) {
      margin: 0.6rem 1.1rem 2rem;
      font-size: 1rem;
    }

    @media (max-width: 1100px) {
      margin: 0.6rem 1.1rem 2rem;
      font-size: 0.85rem;
    }
    @media (max-width: 930px) {
      margin: 1rem 1.5rem 1rem;
      font-size: 1.15rem;
    }

    @media (max-width: 730px) {
      font-size: 1.2rem;
      line-height: 1.5;
      margin: 1rem 1.5rem 1rem;
    }
  }

  span {
    margin: 2rem 1.5rem 2rem;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 1px;
    background-image: none;
    border-width: 1px;
    border-style: none none solid;
    padding: 0;
    cursor: pointer;



    @media (max-width: 1100px) {
      margin: 10px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}