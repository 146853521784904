body {
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  margin: 0;
  overflow-x: hidden;
  background: #ECE9E6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.main {

  .title_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    min-height: 48rem;
    background-size: cover;
    background: url("../assets/img/63.jpg") no-repeat center / cover;

    .header {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .logo {

        img {
          max-width: 58px;
          max-height: 80px;
          padding-left: 84px;
        }
      }

      .search {
        display: flex;
        color: white;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .language {
          margin-right: 30px;
          z-index: 1;

          span {
            position: relative;
            margin: 0 5px 5px;
            cursor: pointer;
            opacity: 0.9;
            font-weight: 400;

            @media (max-width: 430px) {
              opacity: 1;
            }

            &:hover {
              text-shadow:0 0 10px white;
              opacity: 1;
            }
          }
        }
      }
    }

    .home-nav {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      @media (max-width: 840px) {
        display: none;
      }

      nav {
        padding: 10px;
        display: flex;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
      }

      .nav-item {
        position: relative;
        cursor: pointer;
        color: white;
        margin: 0 10px 10px;
        font-size: 18px;
        font-weight: 400;
        text-decoration: none;

        &:hover {
          text-shadow:0 0 10px white;
        }
      }

      .nav-item.hovered .additional-tabs {
        display: block;
      }

      .additional-tabs {
        display: none;
        position: absolute;
        width: 104%;
        font-size: 1rem;
        background-color: rgb(35,35,35, 0.98);
        top: 100%;
        left: 0;
        padding: 10px;
        box-shadow: 0 0 10px rgba(12, 75, 138, 0.5);
        z-index: 20;

        a {
          font-size: 16px;
        }
      }

      .tab {
        display: flex;
        flex-direction: column;
        padding: 5px;
        color: white;
        opacity: 0.8;
        cursor: pointer;
        text-shadow: none;

        &:hover {
          opacity: 1;
          color: white;
        }
      }

      span {
        cursor: pointer;
        color: white;
        margin: 0 16px 10px;
        font-size: 18px;
        font-weight: 400;
      }

      a {
        cursor: pointer;
        margin: 0 10px 10px;
        font-size: 18px;
        font-weight: 400;
        text-decoration: none;
        color: white;

        &:hover {
          text-shadow:0 0 10px white;
        }
      }
    }


    .info {
      width: 70%;
      color: white;
      text-align: left;
      margin-top: 96px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 680px) {
        margin-top: 52px;
      }
      @media (max-width: 430px) {
        margin-top: 36px;
      }

      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 50px;
        font-weight: 500;
        line-height: 1.3;

        @media (max-width: 1240px) {
          font-size: 44px;
        }
        @media (max-width: 1150px) {
          font-size: 36px;
        }
        @media (max-width: 970px) {
          font-size: 34px;
        }
        @media (max-width: 880px) {
          font-size: 26px;
        }
        @media (max-width: 680px) {
          font-size: 30px;
          flex-direction: column;
        }
        @media (max-width: 430px) {
          font-size: 30px;
          flex-direction: column;
        }

        img {
          margin-right: 2rem;
          height: 10rem;
          width: 10rem;

          @media (max-width: 970px) {
            height: 8rem;
            width: 8rem;
          }
          @media (max-width: 880px) {
            height: 6rem;
            width: 6rem;
          }
        }
      }

      .description {
        font-size: 22px;
        font-weight: 300;

        @media (max-width: 880px) {
          font-size: 20px;
        }
        @media (max-width: 730px) {
          font-size: 18px;
        }
        @media (max-width: 680px) {
          font-size: 20px;
        }
        @media (max-width: 530px) {
          font-size: 17px;
        }
      }

      .more_button {
        margin-top: 70px;
        width: 13rem;
        height: 3rem;
        border: 2px solid white;
        padding: 10px;
        cursor: pointer;
        font-weight: 700;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 680px) {
          margin-top: 70px;
        }
        @media (max-width: 430px) {
          margin-top: 60px;
        }

        &:hover {
          background-color: #FFFFFF;
          color: black;
        }
      }
    }
  }


  .industry {
    display: flex;
    justify-content: center;
    padding: 20px 0 20px;
    overflow: hidden;
    margin-top: 30px;

    .aboutUs {
      position: absolute;
      background-color: white;
      border-radius: 5px;
      width: 47rem;
      height: 30rem;
      left: 14rem;
      bottom: -34rem;
      font-size: 1.4rem;
      padding: 2rem 1rem 0 2rem;
      box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.4);

      @media (max-width: 1550px) {
        width: 40rem;
        height: 30rem;
        left: 4rem;
        bottom: -37rem;
        font-size: 1.2rem;
      }
      @media (max-width: 1240px) {
        width: 36rem;
        height: 25rem;
        left: 2rem;
        font-size: 1.1rem;
      }
      @media (max-width: 970px) {
        width: 32rem;
        height: 21rem;
        left: 1rem;
        bottom: -24rem;
        font-size: 1rem;
        padding: 1rem 0 0 1rem;
      }
      @media (max-width: 730px) {
        width: 29rem;
        height: 20em;
        left: 1rem;
        bottom: -18.5rem;
        font-size: 0.9rem;
      }
      @media (max-width: 570px) {
        width: 22rem;
        height: 19rem;
        left: 5px;
        bottom: -20rem;
        font-size: 0.82rem;
      }
      @media (max-width: 470px) {
        width: 25rem;
        height: 20.5rem;
        bottom: -21rem;
        font-size: 0.9rem;
        padding: 0.7rem 0 0 0.7rem;
      }
      @media (max-width: 430px) {
        width: 22.5rem;
        height: 21.3rem;
        bottom: -22rem;
        font-size: 0.84rem;
      }
      @media (min-height: 650px) and (max-width: 470px) {
        width: 22rem;
        height: 20rem;
        left: 5px;
        bottom: -26.5rem;
        font-size: 0.82rem;
      }
      @media (min-height: 730px) and (max-width: 470px) {
        width: 22rem;
        height: 20.5rem;
        bottom: -22.5rem;
        font-size: 0.84rem;
      }
      @media (min-height: 890px) and (max-width: 470px) {
        width: 24rem;
        height: 19.5rem;
        left: 5px;
        bottom: -20.5rem;
        font-size: 0.82rem;
      }

      p {
        margin-top: 4px;
      }
    }

    .aboutUs_img {
      max-width: 74rem;
      max-height: 64rem;
      margin-left: 35%;

      @media (max-width: 1550px) {
        max-width: 64rem;
        max-height: 58rem;
      }
      @media (max-width: 1240px) {
        max-width: 54rem;
        max-height: 50rem;
      }
      @media (max-width: 970px) {
        max-width: 44rem;
        max-height: 46rem;
      }
      @media (max-width: 730px) {
        max-width: 34rem;
        max-height: 36rem;
      }
      @media (max-width: 570px) {
        max-width: 28rem;
        max-height: 30rem;
      }
      @media (max-width: 470px) {
        max-width: 38rem;
        max-height: 34rem;
      }
    }
  }

  .technologies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 40px;
    font-weight: 300;
    overflow: hidden;

    .lines {

      svg {
        max-width: 10rem;
        max-height: 10rem;

        @media (max-width: 730px) {
          max-height: 8rem;
        }
        @media (max-width: 570px) {
          max-height: 6rem;
        }
        @media (max-width: 470px) {
          max-height: 5rem;
        }
      }

      img {
        max-width: 9rem;
        fill: blue;
        bottom: 0;
      }
    }

    img {
      max-width: 29rem;
      position: relative;
      bottom: 5rem;
      z-index: -1;

      @media (max-width: 470px) {
        max-width: 24rem;
      }
    }

    .title {
      font-size: 3rem;
      font-weight: 400;
      margin-top: 3rem;

      @media (max-width: 730px) {
        font-size: 2.5rem;
      }
      @media (max-width: 570px) {
        font-size: 2.5rem;
      }
      @media (max-width: 470px) {
        font-size: 2.4rem;
      }
    }

    div {
      margin-right: 10px;
    }

    .messages {
      display: flex;
      flex-direction: row;

      @media (max-width: 730px) {
        flex-direction: column;
      }

      div {
        width: 20rem;
        height: 18rem;
        background-color: white;
        border-radius: 5px;
        margin: 0 10px;
        box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.4);

        @media (max-width: 1240px) {
          width: 18rem;
          height: 20rem;
        }
        @media (max-width: 970px) {
          width: 14rem;
          height: 18rem;
        }
        @media (max-width: 730px) {
          width: 24rem;
          height: 14rem;
          margin-bottom: 22px;
        }
        @media (max-width: 570px) {
          width: 21rem;
          height: 14rem;
        }
        @media (max-width: 470px) {
          width: 21rem;
          height: 14rem;
          margin-bottom: 22px;
        }

        p {
          margin: 24px 28px;
          text-align: left;

          @media (max-width: 970px) {
            margin: 14px 16px;
          }
          @media (max-width: 730px) {
            margin: 14px 16px;
          }
          @media (max-width: 570px) {
            margin: 10px 20px;
          }
          @media (max-width: 470px) {
            margin: 20px 20px;
          }
        }

        .title {
          font-size: 20px;

          @media (max-width: 970px) {
            font-size: 18px;
          }
          @media (max-width: 730px) {
            font-size: 1.25rem;
          }
          @media (max-width: 570px) {
            font-size: 1.25rem;
          }
          @media (max-width: 470px) {
            font-size: 1.25rem;
          }
        }

        .description {
          font-size: 18px;

          @media (max-width: 970px) {
            font-size: 16px;
          }
          @media (max-width: 730px) {
            font-size: 1rem;
          }
          @media (max-width: 570px) {
            font-size: 1rem;
          }
          @media (max-width: 470px) {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .discussion_block {
    margin: 0;

    .discussion_photo {
      margin: 0;
      padding: 0;
      background-color: #282c34;

      img {
        max-width: 100%;

        @media (max-width: 730px) {
          object-fit: cover;
          display: block;
          vertical-align: middle;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          height: 480px;
          width: 100% !important;
        }
      }
    }

    .principles {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: #282c34;

      @media(max-width: 930px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .triple-block {
    position: relative;
    max-height: 60rem;
    margin-bottom: 40rem;

    @media (max-width: 570px) {
      margin-bottom: 34rem;
    }

    .background-top-block {
      position: relative;
      background-color: #282c34;
      width: 100%;
      height: 24rem;
      z-index: -5;
    }

    .backLine {
      position: relative;
      width: 37rem;
      height: 38rem;
      margin: -14rem calc((100% - 2rem) / 2) 0 auto;
      z-index: -1;
      background-color: #b8bef0;

      @media (max-width: 1240px) {
        width: 30rem;
        height: 32rem;
      }
      @media (max-width: 970px) {
        width: 24rem;
        height: 26rem;
        margin: -14rem calc((100% - 12rem) / 2) 0 auto;
      }
      @media (max-width: 730px) {
        width: 14rem;
        height: 18rem;
        margin: -13rem calc((100% - 2rem) / 2) 0 auto;
      }

      @media (max-width: 570px) {
        width: 12rem;
        height: 34rem;
        margin: -19rem calc((100% - 2rem) / 2) 0 auto;
      }
    }

    .middle {
      position: relative;
      width: 44rem;
      height: 46rem;
      background-color: white;
      margin: -48rem calc((100% - 68rem) / 2) 0 auto;
      z-index: -1;

      @media (max-width: 1240px) {
        width: 38rem;
        height: 62rem;
        margin: -58rem calc((100% - 60rem) / 2) 0 auto;
      }
      @media (max-width: 970px) {
        width: 29rem;
        height: 36rem;
        margin: -31rem calc((100% - 44rem) / 2) 0 auto;
      }
      @media (max-width: 730px) {
        width: 22rem;
        height: 29rem;
        margin: -23rem calc((100% - 32rem) / 2) 0 auto;
      }
      @media (max-width: 570px) {
        width: 18rem;
        height: 50rem;
        margin: -44rem calc((100% - 22rem) / 2) 0 auto;
      }
    }

    .frontLine {
      position: relative;
      width: 50rem;
      height: 39rem;
      margin: -27rem calc((100% - 40rem) / 2) 0 auto;

      @media (max-width: 1240px) {
        width: 30rem;
        margin: -26rem calc((100% - 27rem) / 2) 0 auto;
      }
      @media (max-width: 970px) {
        width: 24rem;
        margin: -19rem calc((100% - 27rem) / 2) 0 auto;
      }
      @media (max-width: 730px) {
        width: 18rem;
        margin: -19rem calc((100% - 17rem) / 2) 0 auto;
      }
      @media (max-width: 570px) {
        width: 18rem;
        margin: -19rem calc((100% - 14rem) / 2) 0 auto;
      }

      img {
        object-fit: cover;
        width: 50rem;
        height: 39rem;
        box-shadow: 0 0 25px 0 rgba(0,0,0,0.3);

        @media (max-width: 1240px) {
          width: 38rem;
          height: 34rem;
        }
        @media (max-width: 970px) {
          width: 30rem;
          height: 26rem;
        }
        @media (max-width: 730px) {
          width: 22rem;
          height: 20rem;
        }
        @media (max-width: 570px) {
          width: 22rem;
          height: 20rem;
        }
      }
    }

    .text-block {
      position: relative;
      width: 40rem;
      height: 23rem;
      margin: -58rem calc((100% - 66rem) / 2) 0 auto;

      @media(max-width: 1240px) {
        width: 33rem;
        height: 23rem;
        margin: -58rem calc((100% - 58rem) / 2) 0 auto;
      }
      @media(max-width: 970px) {
        width: 24rem;
        height: 23rem;
        margin: -57rem calc((100% - 42rem) / 2) 0 auto;
      }
      @media(max-width: 730px) {
        width: 19rem;
        height: 18rem;
        margin: -49rem calc((100% - 30rem) / 2) 0 auto;
      }
      @media (max-width: 570px) {
        width: 15rem;
        height: 18rem;
        margin: -64rem calc((100% - 20rem) / 2) 0 auto;
      }

      h2 {
        font-size: 3rem;
        font-weight: 400;
        margin-bottom: 2rem;

        @media (max-width: 1240px) {
          font-size: 2rem;
        }
        @media (max-width: 730px) {
          margin-bottom: 1rem;
          font-size: 1.1rem;
        }
        @media (max-width: 570px) {
          margin-bottom: 2rem;
          font-size: 2rem;
        }
      }

      p {
        font-family: 'Open Sans',sans-serif;
        font-size: 1.25rem;
        font-weight: 300;
        margin-bottom: 4.5rem;
        font-style: italic;

        @media (max-width: 1240px) {
          font-size: 1rem;
        }
        @media (max-width: 970px) {
          margin-bottom: 3rem;
        }
        @media (max-width: 830px) {
          font-size: 0.85rem;
          margin-bottom: 2rem;
        }
        @media (max-width: 570px) {
          font-size: 1.2rem;
          margin-bottom: 5rem;
        }
      }

      a {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #ffffff !important;
        background-color: #232b79 ;
      }

      .more-button {
        background-image: none;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 2px;
        border-style: none;
        margin: 36px auto 0 0;
        padding: 16px 48px;
        cursor: pointer;
        font-weight: 400;
        text-decoration: none;

        @media (max-width: 730px) {
          padding: 12px 42px;
        }
        @media (max-width: 730px) {
          font-size: 0.9rem;
        }

        &:hover {
          background-color: #242f96;
        }
      }
    }
  }
}
  .new-technologies_block {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 400;
      font-size: 3rem;
      line-height: 1.1;
      margin: 0 auto ;

      @media (max-width: 730px) {
       text-align: center;
        font-size: 2.4rem;
      }
    }

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin: 3rem auto;

      @media (max-width: 730px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    p {
      margin: 1rem 3rem;
      max-width: 21rem;
      line-height: 1.8;
      font-weight: 400;

      @media (max-width: 570px) {
        max-width: 24rem;
        font-size: 1.4rem;
      }
    }
  }

  .future-technology_block {
    overflow: hidden;

    .wrapper {
      display: flex;
      flex-direction: column;

      h2 {
        font-weight: 400;
        font-size: 3rem;
        line-height: 1.1;
        margin: 0 auto ;

        @media (max-width: 570px) {
          text-align: center;
          font-size: 2.4rem;
        }
      }

      .lines {
        display: flex;
        justify-content: center;

        svg {
          max-height: 10rem;
        }
      }

      .triple-bottom_block {
        position: relative;
        padding-bottom: 16rem;

        @media (max-width: 570px) {
          padding-bottom: 10rem;
        }

        .backLine {
          position: relative;
          width: 20.5rem;
          height: 38.5rem;
          background-color: #232b79;
          margin: 0rem calc((100% - 30rem) / 2) 0 auto;
        }

        .middle {
          position: relative;
          width: 46rem;
          height: 30rem;
          margin: -32.5rem calc((100% - 67rem) / 2) 0 auto;

          @media (max-width: 570px) {
            width: 37rem;
            height: 19rem;
            margin: -39.5rem calc((100% - 44rem) / 2) 0 auto;
          }

          img {
            max-width: 46rem;
            height: 30rem;

            @media (max-width: 570px) {
              max-width: 30rem;
              height: 20rem;
            }
          }
        }

        .frontLine {
          position: relative;
          width: 29rem;
          height: 23rem;
          background-color: white;
          margin: -33.5rem calc((100% - -5rem) / 2) 0 auto;
          box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.2);

          @media (max-width: 570px) {
            width: 24rem;
            height: 23rem;
            margin: -4.5rem calc((100% + -24rem) / 2) 0 auto;
          }
          @media (max-width: 400px) {
            width: 22rem;
            height: 26rem;
            margin: -5.5rem calc((100% - 22rem) / 2) 0 auto;
          }
        }

        .text-block {
          position: relative;
          width: 29rem;
          height: 23rem;
          margin: -22.5rem calc((100% - -5rem) / 2) 0 auto;

          @media (max-width: 570px) {
            width: 26rem;
            height: 23rem;
            margin: -23.5rem calc((100% - 26rem) / 2) 0 auto;
          }
          @media (max-width: 400px) {
            width: 22rem;
            height: 20rem;
            margin: -26.5rem calc((100% - 22rem) / 2) 0 auto;
          }

          h5 {
            font-weight: 400;
            font-size: 1.875rem;
            line-height: 1.2;
            margin: 2rem 0 3rem;
          }

          p {
            font-size: 1.1rem;
            line-height: 1.6;
            margin: 0 4rem;
            text-align: left;
          }
        }
      }
    }
  }

  .connection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 42rem;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url("../assets/img/cvb.webp");
    background-position: 50% 50%;
    vertical-align: middle;
    background-size: cover;

    .connection_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      width: 42rem;
      height: 22rem;

      .title {
        font-size: 2.8rem;
        line-height: 1.1;
        font-weight: 400;
        margin-top: 3rem;
      }

      .input {
        display: flex;
        flex-direction: row;
        width: 80%;
        margin-top: 2rem;
        margin-bottom: 4rem;
      }

      .connection_bottom {
        font-size: 1.875rem;
        line-height: 1.2;
        font-weight: 400;
        height: 100%;
        width: 100%;
        text-align: center;
        background-color: #f2f2f2;

        p {
          margin: 1rem;
        }

        .icons {
          padding-bottom: 0.5rem;

          img {
            margin: 0 0.4rem;
            cursor: pointer;
          }

          a {
            text-decoration: none;
            display: contents;
          }
        }
      }
    }
  }

.flying-box {
  top: 34rem;
  position: relative;
  right: 55rem;

  @media (max-width: 1560px) {
    top: 21rem;
  }
  @media (max-width: 1240px) {
    top: 21rem;
  }
  @media (max-width: 970px) {
    top: 21rem;
  }
  @media (max-width: 730px) {
    top: 16rem;
  }
  @media (max-width: 570px) {
    top: 25rem;
  }
  @media (max-width: 470px) {
    top: 18rem;
  }

  .flying-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 23px;
    font-weight: 600;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    width: 15.5rem;
    height: 12rem;
    background-color: white;
    position: relative;
    right: -2000px; /* Начальная позиция за границей экрана */
    transition: right 1.5s ease; /* Анимация перехода */
    box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.2);

    @media (max-width: 1240px) {
      width: 13.5rem;
      height: 10rem;
      font-size: 22px;
    }
    @media (max-width: 970px) {
      width: 10.5rem;
      height: 8rem;
      font-size: 17px;
    }
    @media (max-width: 730px) {
      width: 8rem;
      height: 6.5rem;
      font-size: 15px;
    }
    @media (max-width: 570px) {
      width: 7.5rem;
      height: 6rem;
      font-size: 14px;
    }
    @media (max-width: 470px) {
      width: 6.5rem;
      height: 5.5rem;
      font-size: 12px;
    }

    img {
      max-width: 100px;
      max-height: 70px;

      @media (max-width: 1560px) {
        max-height: 70px;
      }
      @media (max-width: 1240px) {
        max-height: 60px;
      }
      @media (max-width: 970px) {
        max-height: 50px;
      }
      @media (max-width: 730px) {
        max-height: 40px;
      }
      @media (max-width: 570px) {
        max-height: 30px;
      }
      @media (max-width: 470px) {
        max-height: 25px;
      }
    }
  }

  .flying-block.visible.block1 {
    right: -27rem; /* Новая позиция при видимости */
    transition-duration: 1.6s; /* Продолжительность анимации для блока 1 */

    @media (max-width: 1560px) {
      right: -30rem;
    }
    @media (max-width: 1240px) {
      right: -32rem;
    }
    @media (max-width: 970px) {
      right: -34rem;
    }
    @media (max-width: 730px) {
      right: -36rem;
    }
    @media (max-width: 570px) {
      right: -38rem;
    }
    @media (max-width: 470px) {
      right: -38rem;
    }
    @media (max-width: 430px) {
      right: -34rem;
    }
  }

  .flying-block.visible.block2 {
    right: -27.3rem; /* Новая позиция при видимости */
    transition-duration: 1.9s; /* Продолжительность анимации для блока 2 */

    @media (max-width: 1560px) {
      right: -30rem;
    }
    @media (max-width: 1240px) {
      right: -32rem;
    }
    @media (max-width: 970px) {
      right: -34rem;
    }
    @media (max-width: 730px) {
      right: -36rem;
    }
    @media (max-width: 570px) {
      right: -38rem;
    }
    @media (max-width: 470px) {
      right: -38rem;
    }
    @media (max-width: 430px) {
      right: -34rem;
    }
  }

  .flying-block.visible.block3 {
    right: -27rem; /* Новая позиция при видимости */
    transition-duration: 2.3s; /* Продолжительность анимации для блока 3 */

    @media (max-width: 1560px) {
      right: -30rem;
    }
    @media (max-width: 1240px) {
      right: -32rem;
    }
    @media (max-width: 970px) {
      right: -34rem;
    }
    @media (max-width: 730px) {
      right: -36rem;
    }
    @media (max-width: 570px) {
      right: -38rem;
    }
    @media (max-width: 470px) {
      right: -38rem;
    }
    @media (max-width: 430px) {
      right: -34rem;
    }
  }

  .flying-block.visible.block4 {
    right: -27.3rem; /* Новая позиция при видимости */
    transition-duration: 2.5s; /* Продолжительность анимации для блока 4 */

    @media (max-width: 1560px) {
      right: -30rem;
    }
    @media (max-width: 1240px) {
      right: -32rem;
    }
    @media (max-width: 970px) {
      right: -34rem;
    }
    @media (max-width: 730px) {
      right: -36rem;
    }
    @media (max-width: 570px) {
      right: -38rem;
    }
    @media (max-width: 470px) {
      right: -38rem;
    }
    @media (max-width: 430px) {
      right: -34rem;
    }

  }

  /* Дополнительный стиль для блока, который будет запускать анимацию */
  .trigger-block {
    height: 200px; /* Высота, после которой начнется анимация */

    @media (max-width: 570px) {
      height: 150px;
    }
    @media (max-width: 470px) {
      height: 100px;
    }
    @media (max-width: 430px) {
      height: 10px;
    }
  }

  div {
    display: flex;
    margin: 0 10px 10px;
  }
}

.scroll-to-top-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 2rem;
  left: 4rem;
  background-color: #232b79;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 3rem;
  height: 3rem;
  z-index: 100;

  @media(max-width: 730px) {
    left: 2rem;
    top: 3rem;
  }

  &:hover {
    background-color: #242f96;
  }

  &.visible {
    opacity: 1;
  }
}

.instructions-wrapper {
  display: flex;
  flex-direction: column;

  .backToMain {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #2f4f4f;
    display: flex;
    padding: 0.4rem;
    border-radius: 8px;

    &:hover {
      background-color: #3c7373;
    }

    a {
      color: white;
      display: flex;
      align-items: center;
      font-size: 1.15rem;
      font-weight: 400;
      text-decoration: none;

      svg {
        margin-right: 5px;
      }
    }
  }

  .page-title {
    display: flex;
    width: 100%;
    justify-content: space-around;
    background-color: #282c34;
    background-image: url("../assets/img/inspiration-geometry.png");
    color: white;
    padding: 2rem 0;

    @media(min-width: 1300px) {
      min-height: 377px;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    img {
      width: 20rem;

      @media(max-width: 500px) {
        width: 10rem;
      }
    }
  }

  .instructions {

    h5 {
      font-size: 1.5rem;
      margin-bottom: 10px;
      margin-left: 5%;
    }

    .instruction_block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .support {
    display: flex;
    margin: 5rem 7rem;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.2);
    padding: 2rem;

    @media(max-width: 600px) {
      flex-direction: column;
      margin: 3rem 3rem;
    }

    .info {
      display: flex;
      align-items: center;

      @media(max-width: 600px) {
        flex-direction: column;
      }

      div {
        margin-left: 40px;

        p {
          font-size: 1.5rem;
          font-weight: 700;
          margin-bottom: 5px;
        }

        span {
          font-weight: 400;
        }
      }
    }
    .phone-number {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 500;
      margin-right: 3rem;
      cursor: pointer;
    }

    img {
      width: 14rem;

      @media(max-width: 600px) {
        width: 8rem;
      }
    }
  }
}

.modal {
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 100;

  h4 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }

  p {
    font-size: 1.25rem;
  }

  .modal__content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    max-width: 80rem;
    transform: scale(0.5);
    transition: 0.4s all;
    overflow: auto;

    @media (max-width: 1240px) {
      margin: 0 3rem;
      max-height: calc(100vh - 100px);
    }

    div {
      display: flex;
      justify-content: space-between;

      svg {
        max-width: 2rem;
        max-height: 1.5rem;
        fill: #b8b7ad;
        cursor: pointer;
      }
    }
  }

  .modal__content.active {
    transform: scale(1);
  }
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  z-index: 100;
}




