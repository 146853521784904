.wrapper {
  font-size: 14px;
  z-index: 999;
  width: 350px;
  border-radius: 4px;
  min-height: 20px;
  box-sizing: border-box;
  padding: 20px 20px 20px 20px;
  background-color: rgba(35, 35, 35, 0.88);
  overflow: hidden;
  position: fixed;
  bottom: 30px;
  left: 30px;

  @media (max-width: 530px) {
    left: 15px;
  }

  p {
    margin: 0;
    padding: 0;
    color: #fff;
    line-height: 20px;
    display: block;
    font-size: 13px;
    font-weight: 400;

    a {
      color: #FFFFFF;
    }
  }

  .acceptButton {
    display: flex;
    justify-content: right;

    button {
      display: inline-block;
      font-family: OpenSans, arial, "sans-serif";
      color: #fff;
      background-color: #0056b3;
      border: 1px solid #0056b3;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      margin-top: 3px;
      box-sizing: border-box;
      padding: 6px 14px;
      text-align: center;
      cursor: pointer;

      &:hover {
        cursor: pointer;
        background-color: #e5f2ff;
        border: 1px solid #e5f2ff;
        color: #333;
        text-decoration: none;
      }
    }
  }
  .cookiesTitle_wrapper {
    display: block !important;
  }

  .cookies_wrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px);


    p {
      color: #282c34;
      font-size: 15px;
      margin-top: 8px;
    }
  }
}

