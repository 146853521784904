.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  width: 90%;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin-bottom: 14px;
  border-radius: 4px;

  .info {
    display: flex;
    flex-direction: column;


    .instruction_title {
      margin: 0;
      color: black;
      font-size: 1.25rem;
      font-weight: 400;
      cursor: pointer;
      text-decoration: none;


      &:hover {
        color: #0d6efd;
      }
    }
  }
  .download_button {
    display: flex;
    align-items: center;
    cursor: pointer;

    a {
      text-decoration: none;
      font-size: 1.1rem;
      color: black;
      font-weight: 400;

      &:hover {
        color: #0d6efd;
      }
    }
  }

}