/* Position and sizing of burger button */
.bmBurgerButton {
  position: relative;
  width: 44px;
  height: 42px;
  left: 36px;
  top: 2px;

  @media(min-width: 840px) {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bmBurgerBars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bmBurgerBars:hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bmCrossButton {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bmCross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bmMenuWrap {
  position: fixed;
  height: 30%;
}

/* General sidebar styles */
.bmMenu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bmMorphShape {
  fill: #373a47;
}

/* Wrapper for item list */
.bmItemList {
  color: #b8b7ad;
  padding: 0.8em;

  a {
    margin-bottom: 12px;
    color: #b8b7ad;
    text-decoration: none;
    font-size: 20px;
    line-height: 26px;
  }
}

/* Individual item */
.bmItem {
  display: inline-block;
}

/* Styling of overlay */
.bmOverlay {
  background: rgba(0, 0, 0, 0.3);
}