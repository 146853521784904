footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #282c34;
  font-size: 0.9rem;
  color: white;
  padding: 0 2rem;
  overflow: hidden;

  @media (max-width: 780px) {
    flex-direction: column-reverse;
  }

  div {
    margin: 1rem 1rem;
    display: flex;

  }

  .wrapper {
    display: flex;
    flex-direction: row;

    .rights {

      p {
        text-align: center;
      }
    }

  }

  .first_block {
    display: flex;
    flex-direction: column;
    max-width: 26rem;


  }

  .icon_vc {
    height: 4.5rem;

    img {
      max-width: 4.5rem;
    }
  }

  .icon_bks {
    height: 7rem;
    display: flex;
    align-items: center;

    img {
      max-width: 7rem;
    }
  }

  .working_time {
    display: flex;
    flex-direction: column;
    min-width: 20rem;

    h5 {
      margin: 0;
      font-size: 1rem;
      font-weight: 400;
    }

    ul {
      margin-top: 0.4rem;
      list-style: none;
      padding: 0;
    }

    a {
      text-decoration: none;
      color: white;
    }

  }

  .rights {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  img {
    max-width: 4.5rem;
  }

  p {
    margin: 1rem 0 0;
  }
}